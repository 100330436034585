<template>
  <div v-if="findRouteName">
    <cz-collection :class="customClass" :id="elementId" :find-route-name="findRouteName">
      <cz-document-component :form-object="formObject" :prevent-default="true"></cz-document-component>
    </cz-collection>
  </div>
</template>

<script type="text/ecmascript-6">

import DataConfigMixin from '@/mixins/data-config-mixin'

export default {
  // extends   : {},
  mixins    : [DataConfigMixin],
  components: {},
  name      : 'DocumentView',
  props     : {
    className: { type: String, default: '' },
  },
  data() {
    return {
      findRouteName: '',
    }
  },
  watch   : {
    // someObject: { handler: function (val, oldVal) { /* ... */ }, deep: true, immediate: true },
  },
  computed: {
    elementId() {return `DocumentView_${this._uid}`},
    customClass() {return ['DocumentView', this.className]},
    formObject() {
      return this.getValueByPath(this.dataConfig, this.$route.meta.dataPath)
    },
  },
  methods : {
    getValueByPath(object, prop) {
      prop        = prop || ''
      const paths = prop.split('.')
      let current = object
      let result  = null
      for (let i = 0, j = paths.length; i < j; i++) {
        const path = paths[i]
        if (!current) {
          break
        }

        if (i === j - 1) {
          result = current[path]
          break
        }
        current = current[path]
      }
      return result
    },
    getPropByPath(obj, path, strict) {
      let tempObj = obj
      path        = path.replace(/\[(\w+)\]/g, '.$1')
      path        = path.replace(/^\./, '')

      let keyArr = path.split('.')
      let i      = 0
      for (let len = keyArr.length; i < len - 1; ++i) {
        if (!tempObj && !strict) {
          break
        }
        let key = keyArr[i]
        if (key in tempObj) {
          tempObj = tempObj[key]
        }
        else {
          if (strict) {
            throw new Error('please transfer a valid prop path to form item!')
          }
          break
        }
      }
      return {
        o: tempObj,
        k: keyArr[i],
        v: tempObj ? tempObj[keyArr[i]] : null,
      }
    },
  },
  created() {
    // document.documentElement.scrollTop = 0
    this.$nextTick(() => {
      this.findRouteName = this.$route.meta.parent
    })
  },

  destroyed() {},
  mounted() {},
}
</script>

<style scoped lang="scss" rel="stylesheet/scss">
.DocumentView {

}
</style>


